import * as React from 'react';
import {CsvReader} from './CsvReader.js';
import {KdsCsvReader} from './KDSCsvReader.js';
import {newPerformance} from '../api';
import API from '@aws-amplify/api';

import {listPerformanceDancers} from '../../graphql/queries';
import { newKdsDancer, newKdsPerformance, newPerformanceDancer } from '../api';

const UploadPerformances = () => {
	const [csvArray, setCsvArray] = React.useState([]);
	const [kdsData, setKdsData] = React.useState([]);
	// const testQuery = async () => {
	// 	let dancer = "KDS#Alicia-Landsverk#30a4a720-4651-40f0-9597-ca8b1aabd4d9";
	// 	const response = await API.graphql({
	// 		query: listPerformanceDancers,
	// 		variables: {
	// 			filter: {
	// 				kDSDancerID: {
	// 					eq: dancer
	// 				}
	// 			}
	// 		}, 
	// 		authMode: 'AMAZON_COGNITO_USER_POOLS'
	// 	});
	// 	console.log(response);
	// 	return response;
	// };
	// testQuery();
	React.useEffect(() => {
		if(csvArray.length > 0) {
			console.log(csvArray);
			csvArray.forEach((performance) => {
				newPerformance(performance);
			});
		}

		if(kdsData.performances && kdsData.dancers && kdsData.performanceDancers) {
			let performancePromises = kdsData.performances.map(async (performance) => {
				await newKdsPerformance(performance);
			});
			let dancerPromises = kdsData.dancers.map(async (dancer) => {
				await newKdsDancer(dancer);
			});
			let promises = Promise.all([performancePromises, dancerPromises]);
			promises.then(() => {
				console.log(kdsData.performanceDancers);
				kdsData.performanceDancers.forEach((performanceDancer) => {
					newPerformanceDancer(performanceDancer);
					setTimeout(() => {
						console.log('done');
					}, 10);
				});
				// let performanceDancerResponse = await Promise.all(performanceDancerPromises);
				// console.log(performanceDancerResponse);
			});
		}

		// if(kdsArray.length > 0) {
		// 	// console.log(kdsArray);
		// 	kdsArray.forEach((performance) => {
		// 		newKdsPerformance(performance);
		// 	});
		// 	// newKdsPerformance(kdsArray[0]);
		// }
	}, [csvArray, kdsData]);
	return (
		<div>
			<h1 className="text-3xl font-bold">Upload Performances</h1>
			<CsvReader csvArray={csvArray} setCsvArray={(arr) => setCsvArray(arr)} />
			<br />
			<KdsCsvReader kdsData={kdsData} setKdsData={(arr) => setKdsData(arr)} />
		</div>
	);
};

export default UploadPerformances;