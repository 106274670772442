import API from '@aws-amplify/api';
import {createPerformanceDancers, createKDSDancer, createKDSPerformance, createPerformance} from '../graphql/mutations';
import { generateUUID } from '../utils';

export const newPerformance = async (performance) => {
	console.log(performance);
	const primaryKey = `${performance.studio}#${performance.type}#${generateUUID()}`;

	const response = await API.graphql({
		query: createPerformance,
		variables: {input: {
			performanceId: primaryKey, 
			...performance
		}}, 
		authMode: 'AMAZON_COGNITO_USER_POOLS'
	});
	console.log(response);
	return response;
};

// export const newKdsPerformance = async (performance) => {
	
// 	let responses = await Promise.all(promises);
// 	if(responses) {
// 		await newPerformanceDancer({
// 			kDSDancerID: performance.kDSDancerID, kDSPerformanceID: performance.kDSPerformanceID
// 		});
// 	}
// }

export const newKdsDancer = async (kDSDancer) => {
	const dancerResponse = await API.graphql({
		query: createKDSDancer,
		variables: {input: kDSDancer}, 
		authMode: 'AMAZON_COGNITO_USER_POOLS'
	});
	return dancerResponse;
};

export const newKdsPerformance = async (performance) => {
	const performanceResponse = await API.graphql({
		query: createKDSPerformance,
		variables: {input: performance},
		authMode: 'AMAZON_COGNITO_USER_POOLS'
	});
	return performanceResponse;
};

export const newPerformanceDancer = async (performanceDancer) => {
	const response = await API.graphql({
		query: createPerformanceDancers,
		variables: {input: performanceDancer}, 
		authMode: 'AMAZON_COGNITO_USER_POOLS'
	});
	console.log(response);
	return response;
}