/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      orderId
      parentName
      email
      phone
      address
      city
      state
      zip
      orderCode
      status
      studio
      totalPrice
      performanceOrders {
        nextToken
      }
      dancers {
        nextToken
      }
      kdsDancers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      orderId
      parentName
      email
      phone
      address
      city
      state
      zip
      orderCode
      status
      studio
      totalPrice
      performanceOrders {
        nextToken
      }
      dancers {
        nextToken
      }
      kdsDancers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      orderId
      parentName
      email
      phone
      address
      city
      state
      zip
      orderCode
      status
      studio
      totalPrice
      performanceOrders {
        nextToken
      }
      dancers {
        nextToken
      }
      kdsDancers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPerformance = /* GraphQL */ `
  mutation CreatePerformance(
    $input: CreatePerformanceInput!
    $condition: ModelPerformanceConditionInput
  ) {
    createPerformance(input: $input, condition: $condition) {
      performanceId
      studio
      title
      song
      type
      days
      time
      location
      instructor
      costume
      createdAt
      updatedAt
    }
  }
`;
export const updatePerformance = /* GraphQL */ `
  mutation UpdatePerformance(
    $input: UpdatePerformanceInput!
    $condition: ModelPerformanceConditionInput
  ) {
    updatePerformance(input: $input, condition: $condition) {
      performanceId
      studio
      title
      song
      type
      days
      time
      location
      instructor
      costume
      createdAt
      updatedAt
    }
  }
`;
export const deletePerformance = /* GraphQL */ `
  mutation DeletePerformance(
    $input: DeletePerformanceInput!
    $condition: ModelPerformanceConditionInput
  ) {
    deletePerformance(input: $input, condition: $condition) {
      performanceId
      studio
      title
      song
      type
      days
      time
      location
      instructor
      costume
      createdAt
      updatedAt
    }
  }
`;
export const createPerformanceOrder = /* GraphQL */ `
  mutation CreatePerformanceOrder(
    $input: CreatePerformanceOrderInput!
    $condition: ModelPerformanceOrderConditionInput
  ) {
    createPerformanceOrder(input: $input, condition: $condition) {
      performanceOrderId
      orderId
      dancer {
        dancerId
        orderId
        name
        createdAt
        updatedAt
      }
      kdsDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      dancerName
      dancerId
      performance {
        performanceId
        studio
        title
        song
        type
        days
        time
        location
        instructor
        costume
        createdAt
        updatedAt
      }
      performanceTitle
      performanceId
      individual
      group
      bestie
      individualPrice
      groupPrice
      bestiePrice
      createdAt
      updatedAt
      kDSDancerPerformanceOrdersId
    }
  }
`;
export const updatePerformanceOrder = /* GraphQL */ `
  mutation UpdatePerformanceOrder(
    $input: UpdatePerformanceOrderInput!
    $condition: ModelPerformanceOrderConditionInput
  ) {
    updatePerformanceOrder(input: $input, condition: $condition) {
      performanceOrderId
      orderId
      dancer {
        dancerId
        orderId
        name
        createdAt
        updatedAt
      }
      kdsDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      dancerName
      dancerId
      performance {
        performanceId
        studio
        title
        song
        type
        days
        time
        location
        instructor
        costume
        createdAt
        updatedAt
      }
      performanceTitle
      performanceId
      individual
      group
      bestie
      individualPrice
      groupPrice
      bestiePrice
      createdAt
      updatedAt
      kDSDancerPerformanceOrdersId
    }
  }
`;
export const deletePerformanceOrder = /* GraphQL */ `
  mutation DeletePerformanceOrder(
    $input: DeletePerformanceOrderInput!
    $condition: ModelPerformanceOrderConditionInput
  ) {
    deletePerformanceOrder(input: $input, condition: $condition) {
      performanceOrderId
      orderId
      dancer {
        dancerId
        orderId
        name
        createdAt
        updatedAt
      }
      kdsDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      dancerName
      dancerId
      performance {
        performanceId
        studio
        title
        song
        type
        days
        time
        location
        instructor
        costume
        createdAt
        updatedAt
      }
      performanceTitle
      performanceId
      individual
      group
      bestie
      individualPrice
      groupPrice
      bestiePrice
      createdAt
      updatedAt
      kDSDancerPerformanceOrdersId
    }
  }
`;
export const createDancer = /* GraphQL */ `
  mutation CreateDancer(
    $input: CreateDancerInput!
    $condition: ModelDancerConditionInput
  ) {
    createDancer(input: $input, condition: $condition) {
      dancerId
      orderId
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateDancer = /* GraphQL */ `
  mutation UpdateDancer(
    $input: UpdateDancerInput!
    $condition: ModelDancerConditionInput
  ) {
    updateDancer(input: $input, condition: $condition) {
      dancerId
      orderId
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteDancer = /* GraphQL */ `
  mutation DeleteDancer(
    $input: DeleteDancerInput!
    $condition: ModelDancerConditionInput
  ) {
    deleteDancer(input: $input, condition: $condition) {
      dancerId
      orderId
      name
      createdAt
      updatedAt
    }
  }
`;
export const createKDSDancer = /* GraphQL */ `
  mutation CreateKDSDancer(
    $input: CreateKDSDancerInput!
    $condition: ModelKDSDancerConditionInput
  ) {
    createKDSDancer(input: $input, condition: $condition) {
      dancerId
      name
      orders {
        nextToken
      }
      performances {
        nextToken
      }
      performanceOrders {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateKDSDancer = /* GraphQL */ `
  mutation UpdateKDSDancer(
    $input: UpdateKDSDancerInput!
    $condition: ModelKDSDancerConditionInput
  ) {
    updateKDSDancer(input: $input, condition: $condition) {
      dancerId
      name
      orders {
        nextToken
      }
      performances {
        nextToken
      }
      performanceOrders {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteKDSDancer = /* GraphQL */ `
  mutation DeleteKDSDancer(
    $input: DeleteKDSDancerInput!
    $condition: ModelKDSDancerConditionInput
  ) {
    deleteKDSDancer(input: $input, condition: $condition) {
      dancerId
      name
      orders {
        nextToken
      }
      performances {
        nextToken
      }
      performanceOrders {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createKDSPerformance = /* GraphQL */ `
  mutation CreateKDSPerformance(
    $input: CreateKDSPerformanceInput!
    $condition: ModelKDSPerformanceConditionInput
  ) {
    createKDSPerformance(input: $input, condition: $condition) {
      performanceId
      className
      performanceTitle
      time
      instructor
      costume
      studio
      dancers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateKDSPerformance = /* GraphQL */ `
  mutation UpdateKDSPerformance(
    $input: UpdateKDSPerformanceInput!
    $condition: ModelKDSPerformanceConditionInput
  ) {
    updateKDSPerformance(input: $input, condition: $condition) {
      performanceId
      className
      performanceTitle
      time
      instructor
      costume
      studio
      dancers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteKDSPerformance = /* GraphQL */ `
  mutation DeleteKDSPerformance(
    $input: DeleteKDSPerformanceInput!
    $condition: ModelKDSPerformanceConditionInput
  ) {
    deleteKDSPerformance(input: $input, condition: $condition) {
      performanceId
      className
      performanceTitle
      time
      instructor
      costume
      studio
      dancers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOrderDancers = /* GraphQL */ `
  mutation CreateOrderDancers(
    $input: CreateOrderDancersInput!
    $condition: ModelOrderDancersConditionInput
  ) {
    createOrderDancers(input: $input, condition: $condition) {
      id
      orderID
      kDSDancerID
      order {
        orderId
        parentName
        email
        phone
        address
        city
        state
        zip
        orderCode
        status
        studio
        totalPrice
        createdAt
        updatedAt
      }
      kDSDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderDancers = /* GraphQL */ `
  mutation UpdateOrderDancers(
    $input: UpdateOrderDancersInput!
    $condition: ModelOrderDancersConditionInput
  ) {
    updateOrderDancers(input: $input, condition: $condition) {
      id
      orderID
      kDSDancerID
      order {
        orderId
        parentName
        email
        phone
        address
        city
        state
        zip
        orderCode
        status
        studio
        totalPrice
        createdAt
        updatedAt
      }
      kDSDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderDancers = /* GraphQL */ `
  mutation DeleteOrderDancers(
    $input: DeleteOrderDancersInput!
    $condition: ModelOrderDancersConditionInput
  ) {
    deleteOrderDancers(input: $input, condition: $condition) {
      id
      orderID
      kDSDancerID
      order {
        orderId
        parentName
        email
        phone
        address
        city
        state
        zip
        orderCode
        status
        studio
        totalPrice
        createdAt
        updatedAt
      }
      kDSDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPerformanceDancers = /* GraphQL */ `
  mutation CreatePerformanceDancers(
    $input: CreatePerformanceDancersInput!
    $condition: ModelPerformanceDancersConditionInput
  ) {
    createPerformanceDancers(input: $input, condition: $condition) {
      id
      kDSDancerID
      kDSPerformanceID
      kDSDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      kDSPerformance {
        performanceId
        className
        performanceTitle
        time
        instructor
        costume
        studio
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePerformanceDancers = /* GraphQL */ `
  mutation UpdatePerformanceDancers(
    $input: UpdatePerformanceDancersInput!
    $condition: ModelPerformanceDancersConditionInput
  ) {
    updatePerformanceDancers(input: $input, condition: $condition) {
      id
      kDSDancerID
      kDSPerformanceID
      kDSDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      kDSPerformance {
        performanceId
        className
        performanceTitle
        time
        instructor
        costume
        studio
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePerformanceDancers = /* GraphQL */ `
  mutation DeletePerformanceDancers(
    $input: DeletePerformanceDancersInput!
    $condition: ModelPerformanceDancersConditionInput
  ) {
    deletePerformanceDancers(input: $input, condition: $condition) {
      id
      kDSDancerID
      kDSPerformanceID
      kDSDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      kDSPerformance {
        performanceId
        className
        performanceTitle
        time
        instructor
        costume
        studio
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
