import {Routes, Route } from 'react-router-dom';
import Admin from './Admin';
import Checkout from './Checkout';
import OrderForm from './OrderForm';
import ThankYou from './ThankYou';
import Start from './Start';

const Root = () => {
	return (
		<Routes>
			<Route path="/order" element={<OrderForm />} />
			<Route path="/checkout" element={<Checkout />} />
			<Route path="/thank-you" element={<ThankYou />} />
			<Route path="/admin" element={<Admin />} />
			<Route path="/enc" element={<Start studio="ENC" />} />
			<Route path="/wctd" element={<Start studio="WCTDSR" />} />
			<Route path="/wctdsr" element={<Start studio="WCTDSR" />} />
			<Route path="/kds" element={<Start studio="KDS" />} />
			<Route path="/null" element={<Start studio={sessionStorage.getItem('studio')} />} />
			<Route path="/" element={<Start studio="UNK" />} />
		</Routes>
	);
};

export default Root;
