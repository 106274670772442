import * as React from 'react';
import * as queries from '../queries';

const Reports = () => {
	const [performances, setPerformances] = React.useState({});
	const [searchTerm, setSearchTerm] = React.useState('');
	const [searchResults, setSearchResults] = React.useState([]);
	const [perfOrders, setPerfOrders] = React.useState([]);
	const [selectedStudio, setSelectedStudio] = React.useState('WCTD');

	const getStudioPerformances = async (studio) => {
		const response = await queries.performancesByStudio(studio);
		// console.log(response)
		return response;
	};

	const getPerformanceOrders = async (performanceId) => {
		await console.log(performanceId);
		console.log(selectedStudio)
		if(selectedStudio === 'KDS') {
			const response = await queries.perfOrdersByKdsPerformance(performanceId);
			console.log(response);
			setPerfOrders(response.data.performanceOrderByKDSPerformance.items);
		} else {
			const response = await queries.perfOrdersByPerformance(performanceId);
			console.log(response);
			setPerfOrders(response.data.performanceOrderByPerformance.items);
		}
	};

	const search = async () => {
		const response = await queries.searchPerformances(searchTerm, selectedStudio);
		console.log(response);
		setSearchResults(response);
	};

	React.useEffect(() => {
		const promises = async () => {
			const wctd = await getStudioPerformances('wctd');
			const wctdsr = await getStudioPerformances('wctdsr');
			const ENC = await getStudioPerformances('ENC');
			const KDS = await getStudioPerformances('KDS');
			Promise.all([wctd, ENC, KDS]).then(() => {
				setPerformances({wctd, wctdsr, ENC, KDS});
			});
		};
		promises();
	}, []);
	React.useEffect(() => {
		search();
	}, [searchTerm]);	
	return (
		<div>
			<h1 className="text-3xl font-bold mb-8">Reports</h1>
			
			<div className="px-6 mb-6 border-2 rounded">
				<div className="flex items-center py-6">
					<p className="font-bold text-lg mr-4">Select Studio:</p>
					<button 
						type="button" 
						className={`rounded px-4 py-2 mr-2 ${selectedStudio === 'WCTD' && `bg-blue-500 text-white`}`}
						onClick={() => setSelectedStudio('WCTD')}>
						WCTD
					</button>
					<button 
						type="button" 
						className={`rounded px-4 py-2 mr-2 ${selectedStudio === 'wctdsr' && `bg-blue-500 text-white`}`}
						onClick={() => setSelectedStudio('wctdsr')}>
						WCTD SR
					</button>
					<button 
						type="button"
						className={`rounded px-4 py-2 mr-2 ${selectedStudio === 'ENC' && `bg-blue-500 text-white`}`}
						onClick={() => setSelectedStudio('ENC')}>
						ENC
					</button>
					<button 
						type="button"
						className={`rounded px-4 py-2 mr-2 ${selectedStudio === 'KDS' && `bg-blue-500 text-white`}`}
						onClick={() => setSelectedStudio('KDS')}>
						KDS
					</button>
				</div>
				<input
					type="text"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					placeholder="Search for a performance"
				/>
			</div>
			{/* {console.log(performances)} */}
			{/* {Object.keys(performances).map((studio) => {
				return (
					<div key={studio}>
						<h2 className="text-2xl font-bold">{studio}</h2>
						<ul>
							{performances[studio].map((performance) => {
								return <li key={performance.performanceId} onClick={() => getPerformanceOrders(performance.performanceId, studio)}>{performance.title}</li>;
							})}
						</ul>
					</div>
				);
			})} */}
			<div className="flex justify-start">
				<div className="w-1/3">
					<h2 className="text-2xl font-bold">Performances</h2>
					<ul>
						{searchResults.map((performance, key) => {
							return (
								<li className="cursor-pointer text-blue-600 underline py-1" key={key} onClick={() => getPerformanceOrders(performance.performanceId)}>
									{`${performance.studio} - ${selectedStudio !== 'KDS' ? performance.title : performance.className} - ${performance.days} ${performance.time}`}
								</li>
							);
						})}
					</ul>
				</div>
				<div className="w-2/3 px-6">
					{perfOrders.length > 0 && (
						<>
						<h2 className="text-2xl font-bold mb-4">{perfOrders[0].performanceTitle}</h2>
						<table className="w-full border-2">
							<thead>
								<tr className="bg-slate-300">
									<th className="text-left p-2">Dancer</th>
									<th className="text-left p-2">Individual</th>
									<th className="text-left p-2">Group</th>
									<th className="text-left p-2">Bestie</th>
								</tr>
							</thead>
							<tbody>
								{perfOrders.map((order, key) => {
									return (
										<tr 
											key={key}
											className="border-2">
											<td className="font-bold p-2">{order.dancerName}</td>
											<td className="p-2">{order.individual ? 'Yes' : 'No'}</td>
											<td className="p-2">{order.group ? 'Yes' : 'No'}</td>
											<td className="p-2">{order.bestie ? 'Yes' : 'No'}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						</>
					)}
				</div>
			</div>		
		</div>
	);
};

export default Reports;