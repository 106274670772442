import * as React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {Controller, useFormContext} from 'react-hook-form';

export const ConnectForm = ({ children }) => {
	const methods = useFormContext();
	return children({ ...methods });
};

export const TextInput = ({ type, name, label, ...rest }) => {
	const methods = useFormContext();
	const {register, formState} = methods;
	const {errors} = formState;
	return (
		<div className="mb-6">
			<label htmlFor={name} className="font-bold text-slate-800 text-base md:text-lg inline-block mr-2 md:mr-4 pb-1 pl-1">{label}</label>
			{errors[name]?.message && (
				<span className="text-red-600 font-semibold">This field is required</span>
			)}
			<input 
			type={type || 'text'} 
			className="rounded-lg mb-0" 
			{...register(name)} 
			{...rest} />
			
		</div>
		
	);
}

export const Checkbox = ({ name, label, ...rest }) => {
	const methods = useFormContext();
	const {register} = methods;
	return (
		<div className="flex flex-col items-center justify-center">
			<label htmlFor={name}>{label}</label>
			<input
				type="checkbox"
				className="w-6 h-6 rounded-md checked:text-sky-900"
				{...register(name)}
				{...rest} />
		</div>
	);
};

export const DaysAndTime = ({ daylabel, timelabel, ...rest }) => {
	const times = [];
	let time = new Date('2020-01-01T15:00:00');
	while (time.getHours() < 23) {
		times.push(time.toLocaleTimeString('en-US', {hour: 'numeric', minute: '2-digit'}));
		time.setMinutes(time.getMinutes() + 15);
	}
	return (
		<div className="flex justify-between items-center">
			<div className="w-3/5">
				<p>{daylabel}</p>
				<div className="flex justify-between items-center">
					<Checkbox name="mon" label="Mon" />
					<Checkbox name="tue" label="Tue" />
					<Checkbox name="wed" label="Wed" />
					<Checkbox name="thu" label="Thu" />
					<Checkbox name="fri" label="Fri" />
				</div>
			</div>
			<div className="w-1/5">
				<p>{timelabel}</p>
				<div className="flex justify-between items-center">
					<Select name="time" options={times} />
				</div>
			</div>
		</div>
	);
};

export const Select = ({ options, name, label, ...rest }) => {
	const methods = useFormContext();
	const {register} = methods;
	return (
		<div className="mb-6 flex flex-col">
			<label htmlFor={name} className="font-bold text-slate-800 text-base md:text-lg block pb-1 pl-1">{label}</label>
			<select {...register(name)} {...rest} className="rounded-md block">
				{options.map(value => (
				<option key={value} value={value}>
					{value}
				</option>
				))}
			</select>
		</div>
	);
};

export const DateInput = ({ name, label, ...rest}) => {
	const methods = useFormContext();
	const {control} = methods;
	return (
		<div>
			<label htmlFor={name}>{label}</label>
			<Controller 
				name={name}
				control={control}
				render={({ field }) => (
					<div className="flex justify-between items-center">
						<DatePicker
							className="w-32 h-10 rounded-md checked:text-sky-900"
							placeholderText='Select Date'
							showTimeSelect
							timeIntervals={15}
							onChange={(date) => field.onChange(date)}
							selected={field.value}
						/>
					</div>
				)}
			/>
		</div>
		
	);
}