import {generateUUID} from '../utils';
import {API} from 'aws-amplify';
import * as mutations from '../graphql/mutations';

export const saveDancers = async (dancers, studio, orderId) => {
	let responses = await dancers.map(async (dancer) => {
		const primaryKey = `${studio}#${dancer.split(' ').join('-')}#${generateUUID()}`;
		const response = await API.graphql({
			query: mutations.createDancer,
			variables: {input: {
				dancerId: primaryKey,
				name: dancer,
				orderId: orderId
			}},
		});
		return response;
	});
	let data = await Promise.all(responses);
	data = data.map((item) => item.data.createDancer);
	return data;
}

export const savePerformanceOrders = async (orders, dancers, orderId) => {
	let responses = await orders.map(async (order) => {
		const inputObj = {
			...order,
			performanceOrderId: order.performanceOrderId,
			dancerId: dancers.find((dancer) => dancer.name === order.dancer).dancerId,
			dancerName: order.dancer,
			performanceId: order.performance.performanceId,
			performanceTitle: order.performance.title,
			orderId: orderId
		};
		delete inputObj.dancer;
		delete inputObj.performance;
		delete inputObj.order;
		// console.log(inputObj);
		const response = await API.graphql({
			query: mutations.createPerformanceOrder,
			variables: {input: inputObj},
		});
		return response;
	});
	let data = await Promise.all(responses);
	data = data.map((item) => item.data.createPerformanceOrder);
	return data;
}
export const saveKdsPerformanceOrders = async (orders, orderId) => {
	let responses = await orders.map(async (order) => {
		const inputObj = {
			...order,
			performanceOrderId: order.performanceOrderId,
			dancerId: order.dancerId,
			dancerName: order.dancer,
			performanceTitle: order.performance.title,
			kdsPerformanceId: order.performance.performanceId,
			kdsPerformanceTitle: order.performance.title,
			orderId: orderId
		};
		delete inputObj.dancer;
		delete inputObj.performance;
		delete inputObj.order;
		// console.log(inputObj);
		const response = await API.graphql({
			query: mutations.createPerformanceOrder,
			variables: {input: inputObj},
		});
		return response;
	});
	let data = await Promise.all(responses);
	data = data.map((item) => item.data.createPerformanceOrder);
	return data;
}
export const submitOrder = async (data, orderCode, totalPrice, orderNumber) => {
	const primaryKey = orderNumber;
	const inputObj = {
		...data,
		orderId: primaryKey,
		orderCode: orderCode,
		status: 'Complete',
		totalPrice: totalPrice
	}
	console.log(inputObj);	
	const response = await API.graphql({
		query: mutations.createOrder,
		variables: {input: inputObj},
	});
	return response.data.createOrder;
}