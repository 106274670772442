import * as React from 'react';
import algoliasearch from 'algoliasearch/lite';
import styles from './styles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const searchClient = algoliasearch(
	'BTSI5LSUU5',
	'de44e8159275bb708193b4d1a64a3c15'
);
const searchIndex = searchClient.initIndex(process.env.REACT_APP_KDS_ALGOLIA_INDEX);

const DancerSearch = ({selectedDancers, setSelectedDancers, invalidDancers, setInvalidDancers}) => {
	const [searchTerm, setSearchTerm] = React.useState('');
	const [results, setResults] = React.useState([]);

	React.useEffect(() => {
		if(searchTerm.length > 1) {
			search(searchTerm);
		}
	}, [searchTerm]);
	
	const search = async (term) => {
		const {hits} = await searchIndex.search(term, {
			hitsPerPage: 5,
		});
		setResults(hits);
	};

	const selectDancer = (select) => {
		let dancers = [...selectedDancers];
		console.log(invalidDancers);
		if(!dancers.some((item) => item.dancerId === select.dancerId)) {
			dancers.push(select.name);
		}
		console.log(dancers);
		// remove items items from dancers if they exist in invalidDancers
		dancers = dancers.filter((item) => !invalidDancers.some((invalid) => invalid === item));
		console.log(dancers);
		setInvalidDancers([]);
		setSelectedDancers(dancers);
		sessionStorage.setItem('dancers', JSON.stringify(dancers));
		setResults([]);
	};

	return (
		<div className="col-span-3 relative mb-10">
			<form onSubmit={(e) => e.preventDefault()} className="flex items-end w-full">
			{/* <div className="flex items-end w-full"> */}
				<div className="flex flex-col justify-end w-full">
					{/* <label htmlFor="search" className="block text-lg font-bold mb-1 ml-1">Search for your dancer</label> */}
					<div className="relative">
						<input 
							className="m-0 px-6 py-3 border-2 rounded-lg block"
							type="text" 
							name="search" 
							placeholder="Start typing to find your dancer..."
							autoComplete="off" 
							defaultValue="" 
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
						<button type="submit" className="absolute right-3 bottom-3">
							<FontAwesomeIcon icon={faSearch} className="text-teal-800 text-lg" />
						</button>
					</div>
					
				</div>
				{/* <Button type="submit" buttonStyle="primary">Search</Button> */}
			</form>
			{results.length > 0 ? (
				<div className={`${styles.results} w-full absolute z-10 bg-white shadow-xl`}>
					<ul className="p-4">
						{results.map(result => {
							// const day = formatDay(result.days);
							return (
								<li key={result.dancerId} className="p-1 border-b-2 block cursor-pointer justify-between items-center rounded hover:bg-slate-200" onClick={() => selectDancer(result)}>
									<p>{result.name}</p>
									{/* <div className="inline-flex flex-col w-[40%] md:w-[30%] mb-auto">
										<p className={`text-sm md:text-base font-bold ${styles.title}`}>{result.title}</p>
										<p className={`text-sm md:text-base ${styles.day}`}>{day}</p>
										<p className={`text-sm md:text-base ${styles.time}`}>{result.time}</p>
									</div>
									<div className="hidden md:inline-flex flex-col w-[30%]">
										<p className={`text-sm md:text-base ${styles.instructor}`}>{result.instructor}</p>
										<p className={`text-sm md:text-base ${styles.song}`}><em>"{result.song}"</em></p>
									</div>
									<div className="inline-flex flex-col w-[60%] md:w-[40%]">
										<p className={`text-sm md:text-base md:hidden ${styles.instructor}`}>{result.instructor}</p>
										<p className={`text-sm md:text-base md:hidden ${styles.song}`}><em>"{result.song}"</em></p>
										<p className={`text-sm md:text-base ${styles.costume}`}>{result.costume}</p>
									</div> */}
								</li>
							)
						})}
					</ul>
				</div>
			) : null}
		</div>
	);
};

export default DancerSearch;
