/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrder = /* GraphQL */ `
  query GetOrder($orderId: ID!) {
    getOrder(orderId: $orderId) {
      orderId
      parentName
      email
      phone
      address
      city
      state
      zip
      orderCode
      status
      studio
      totalPrice
      performanceOrders {
        nextToken
      }
      dancers {
        nextToken
      }
      kdsDancers {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $orderId: ID
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      orderId: $orderId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        orderId
        parentName
        email
        phone
        address
        city
        state
        zip
        orderCode
        status
        studio
        totalPrice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orderByEmail = /* GraphQL */ `
  query OrderByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        orderId
        parentName
        email
        phone
        address
        city
        state
        zip
        orderCode
        status
        studio
        totalPrice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orderByStudio = /* GraphQL */ `
  query OrderByStudio(
    $studio: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByStudio(
      studio: $studio
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        orderId
        parentName
        email
        phone
        address
        city
        state
        zip
        orderCode
        status
        studio
        totalPrice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPerformance = /* GraphQL */ `
  query GetPerformance($performanceId: ID!) {
    getPerformance(performanceId: $performanceId) {
      performanceId
      studio
      title
      song
      type
      days
      time
      location
      instructor
      costume
      createdAt
      updatedAt
    }
  }
`;
export const listPerformances = /* GraphQL */ `
  query ListPerformances(
    $performanceId: ID
    $filter: ModelPerformanceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPerformances(
      performanceId: $performanceId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        performanceId
        studio
        title
        song
        type
        days
        time
        location
        instructor
        costume
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const performanceByStudio = /* GraphQL */ `
  query PerformanceByStudio(
    $studio: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPerformanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    performanceByStudio(
      studio: $studio
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        performanceId
        studio
        title
        song
        type
        days
        time
        location
        instructor
        costume
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPerformanceOrder = /* GraphQL */ `
  query GetPerformanceOrder($performanceOrderId: ID!) {
    getPerformanceOrder(performanceOrderId: $performanceOrderId) {
      performanceOrderId
      orderId
      dancer {
        dancerId
        orderId
        name
        createdAt
        updatedAt
      }
      kdsDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      dancerName
      dancerId
      performance {
        performanceId
        studio
        title
        song
        type
        days
        time
        location
        instructor
        costume
        createdAt
        updatedAt
      }
      performanceTitle
      performanceId
      individual
      group
      bestie
      individualPrice
      groupPrice
      bestiePrice
      createdAt
      updatedAt
      kDSDancerPerformanceOrdersId
    }
  }
`;
export const listPerformanceOrders = /* GraphQL */ `
  query ListPerformanceOrders(
    $performanceOrderId: ID
    $filter: ModelPerformanceOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPerformanceOrders(
      performanceOrderId: $performanceOrderId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        performanceOrderId
        orderId
        dancerName
        dancerId
        performanceTitle
        performanceId
        individual
        group
        bestie
        individualPrice
        groupPrice
        bestiePrice
        createdAt
        updatedAt
        kDSDancerPerformanceOrdersId
      }
      nextToken
    }
  }
`;
export const performanceOrderByOrder = /* GraphQL */ `
  query PerformanceOrderByOrder(
    $orderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPerformanceOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    performanceOrderByOrder(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        performanceOrderId
        orderId
        dancerName
        dancerId
        performanceTitle
        performanceId
        individual
        group
        bestie
        individualPrice
        groupPrice
        bestiePrice
        createdAt
        updatedAt
        kDSDancerPerformanceOrdersId
      }
      nextToken
    }
  }
`;
export const performanceOrderByDancer = /* GraphQL */ `
  query PerformanceOrderByDancer(
    $dancerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPerformanceOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    performanceOrderByDancer(
      dancerId: $dancerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        performanceOrderId
        orderId
        dancerName
        dancerId
        performanceTitle
        performanceId
        individual
        group
        bestie
        individualPrice
        groupPrice
        bestiePrice
        createdAt
        updatedAt
        kDSDancerPerformanceOrdersId
      }
      nextToken
    }
  }
`;
export const getPerformanceOrderForPerformance = /* GraphQL */ `
  query GetPerformanceOrdersForPerformance ($performanceId: ID! ) {
    performanceOrderByPerformance(performanceId: $performanceId) {
      items {
        performanceTitle
        bestie
        dancerName
        group
        individual
      }
    }
  }
`;
export const getPerformanceOrderForKdsPerformance = /* GraphQL */ `
  query GetPerformanceOrdersForKdsPerformance ($kdsPerformanceId: ID! ) {
    performanceOrderByKDSPerformance(kdsPerformanceId: $kdsPerformanceId) {
      items {
        performanceTitle
        bestie
        dancerName
        group
        individual
      }
    }
  }
`;
export const performanceOrderByPerformance = /* GraphQL */ `
  query PerformanceOrderByPerformance(
    $performanceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPerformanceOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    performanceOrderByPerformance(
      performanceId: $performanceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        performanceOrderId
        orderId
        dancerName
        dancerId
        performanceTitle
        performanceId
        kDSPerformanceId
        kDSPerformanceTitle
        kDSPerformance {
          performanceId
          studio
          performanceTitle
          className
          costume
          instructor
        }
        individual
        group
        bestie
        individualPrice
        groupPrice
        bestiePrice
        createdAt
        updatedAt
        kDSDancerPerformanceOrdersId
      }
      nextToken
    }
  }
`;
export const getDancer = /* GraphQL */ `
  query GetDancer($dancerId: ID!) {
    getDancer(dancerId: $dancerId) {
      dancerId
      orderId
      name
      createdAt
      updatedAt
    }
  }
`;
export const listDancers = /* GraphQL */ `
  query ListDancers(
    $dancerId: ID
    $filter: ModelDancerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDancers(
      dancerId: $dancerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dancerId
        orderId
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dancerByOrder = /* GraphQL */ `
  query DancerByOrder(
    $orderId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDancerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dancerByOrder(
      orderId: $orderId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dancerId
        orderId
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKDSDancer = /* GraphQL */ `
  query GetKDSDancer($dancerId: ID!) {
    getKDSDancer(dancerId: $dancerId) {
      dancerId
      name
      orders {
        nextToken
      }
      performances {
        items {
          performanceId
          studio
          performanceTitle
          className
          costume
          instructor
          time
        }
        nextToken
      }
      performanceOrders {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listKDSDancers = /* GraphQL */ `
  query ListKDSDancers(
    $dancerId: ID
    $filter: ModelKDSDancerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKDSDancers(
      dancerId: $dancerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dancerId
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKDSPerformance = /* GraphQL */ `
  query GetKDSPerformance($performanceId: ID!) {
    getKDSPerformance(performanceId: $performanceId) {
      performanceId
      className
      performanceTitle
      time
      instructor
      costume
      studio
      dancers {
        items {
          dancerId
          name
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listKDSPerformances = /* GraphQL */ `
  query ListKDSPerformances(
    $performanceId: ID
    $filter: ModelKDSPerformanceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKDSPerformances(
      performanceId: $performanceId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        performanceId
        className
        performanceTitle
        time
        instructor
        costume
        studio 
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderDancers = /* GraphQL */ `
  query GetOrderDancers($id: ID!) {
    getOrderDancers(id: $id) {
      id
      orderID
      kDSDancerID
      order {
        orderId
        parentName
        email
        phone
        address
        city
        state
        zip
        orderCode
        status
        studio
        totalPrice
        createdAt
        updatedAt
      }
      kDSDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrderDancers = /* GraphQL */ `
  query ListOrderDancers(
    $filter: ModelOrderDancersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderDancers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderID
        kDSDancerID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPerformanceDancers = /* GraphQL */ `
  query GetPerformanceDancers($id: ID!) {
    getPerformanceDancers(id: $id) {
      id
      kDSDancerID
      kDSPerformanceID
      kDSDancer {
        dancerId
        name
        createdAt
        updatedAt
      }
      kDSPerformance {
        performanceId
        className
        performanceTitle
        time
        instructor
        costume
        studio
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPerformanceDancers = /* GraphQL */ `
  query ListPerformanceDancers(
    $filter: ModelPerformanceDancersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPerformanceDancers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        kDSDancerID
        kDSPerformanceID
        kDSDancer {
          dancerId
          name
          createdAt
          updatedAt
        }
        kDSPerformance {
          performanceId
          className
          performanceTitle
          time
          instructor
          costume
          studio
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
