import * as React from 'react';
import Cart from '../../Cart';
import { Form, TextInput, Select } from '../../components';
import { States } from '../../utils';
import { useStripe, useElements} from '@stripe/react-stripe-js';
import LogRocket from 'logrocket';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const CheckoutForm = () => {
	const dancers = JSON.parse(sessionStorage.getItem('dancers'));
	const orderItems = JSON.parse(sessionStorage.getItem('performanceOrders'));
	const studio = sessionStorage.getItem('studio');

	const stripe = useStripe();
	const elements = useElements();
	const [isLoading, setIsLoading] = React.useState(false);
	const [message, setMessage] = React.useState(null);

	const onSubmit = async (data) => {
		LogRocket.identify(data.email, {
			name: data.parentName,
			email: data.email,
		})
		console.log(data, dancers, orderItems);

		sessionStorage.setItem('orderData', JSON.stringify(data));
		
		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}
		setIsLoading(true);
		
		const {error} = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: `${process.env.REACT_APP_STRIPE_RETURN_URL}thank-you`,
				receipt_email: data.email,
			},
		})
	
		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
		} else {
			setMessage("An unexpected error occured.");
		}
	
		setIsLoading(false);
	};
	return (
		<>
			<Link to={`/order`}>
				<p className="mb-4 text-lg font-bold text-teal-900"><FontAwesomeIcon icon={faChevronLeft} /> Back</p>
			</Link>
			<Form id="order-form" className="grid grid-cols-3 gap-6" onSubmit={onSubmit}>
				<section className="col-span-3 md:col-span-2">
					<div className="w-full pr-4">
					<TextInput id="parentName" name="parentName" type="text" placeholder="Full Name" label="Enter your full name" />
					</div>
					<div className="w-full pr-4">
						<TextInput id="email" type="email" name="email" label="Email Address" placeholder="Enter your email address" />
					</div>
					<div className="w-full pr-4">
						<TextInput id="phone" type="text" name="phone" placeholder="Enter your phone number" label="Phone Number" />
					</div>
					<div className="w-full pr-4">
						<TextInput id="address" type="text" name="address" placeholder="Enter your address" label="Address" />
					</div>
					<div className="w-full pr-4 flex justify-between">
						<div className="w-1/2 pr-4">
							<TextInput id="city" type="text" name="city" placeholder="Enter your city" label="City"  />
						</div>
						<div className="w-1/4 pr-4">
							<Select options={States()} id="state" name="state" label="State" />
						</div>
						<div className="w-1/4">
							<TextInput id="zip" type="text" name="zip" placeholder="Enter your zip code" label="Zip Code" />
						</div>
					</div>
				</section>
				<aside className="col-span-3 md:col-span-1 row-auto">
					<Cart performanceOrders={orderItems} isCheckout={true} isLoading={isLoading} stripe={stripe} elements={elements} message={message} />
				</aside>
			</Form>
		</>
	)
};

export default CheckoutForm;