import { useState } from 'react'
import {Button} from '../../components';

export function CsvReader({csvArray, setCsvArray}){
    const [csvFile, setCsvFile] = useState();
    
    // [{name: "", age: 0, rank: ""},{name: "", age: 0, rank: ""}]

    const processCSV = (str, delim=',') => {
        const headers = str.slice(0,str.indexOf('\n')).split(delim);
        const rows = str.slice(str.indexOf('\n')+1).split('\n');

        let newArray = rows.map( row => {
            const values = row.split(delim);
            const eachObject = headers.reduce((obj, header, i) => {
				if(header === 'type' && values[i] === '') return null;
				if(header === 'time' && values[i].indexOf('a.m.') === -1) {
					obj[header] = values[i];
				} else {
					obj[header] = values[i];
				}
                return obj;
            }, {})
            return eachObject;
        });
		newArray = newArray.map((row) => {
			const {type, studio, title, days, time, instructor, location, song, costume } = row;
			return {
				type,
				studio,
				title,
				days,
				time,
				instructor,
                location,
				song,
				costume,
			}
		});
		setCsvArray(newArray);
		// console.log(newArray);
    }

    const submit = () => {
        const file = csvFile;
        const reader = new FileReader();

        reader.onload = function(e) {
            const text = e.target.result;
            // console.log(text);
            processCSV(text)
        }

        reader.readAsText(file);
    }

    return(
        <form id='csv-form'>
            <input
                type='file'
                accept='.csv'
                id='csvFile'
                onChange={(e) => {
                    setCsvFile(e.target.files[0])
                }}
            >
            </input>
            <br/>
            <Button
                buttonStyle='primary'
                onClick={(e) => {
                    e.preventDefault()
                    if(csvFile)submit()
                }}
            >
                Submit
            </Button>
            <br/>
            <br/>
            {csvArray.length>0 ? 
            <>
                <table>
					<thead>
						<tr>
							<th>Studio</th>
							<th>Type</th>
							<th>Title</th>
							<th>Days</th>
							<th>Time</th>
							<th>Instructor</th>
                            <th>Location</th>
							<th>Song</th>
							<th>Costume</th>
						</tr>
					</thead>
					<tbody>
                        {
                            csvArray.map((item, i) => {
								// console.log(item);
								return (
									<tr key={i}>
										<td>{item.type}</td>
										<td>{item.studio}</td>
										<td>{item.title}</td>
										<td>{item.days}</td>
										<td>{item.time}</td>
										<td>{item.instructor}</td>
                                        <td>{item.location}</td>
										<td>{item.song}</td>
										<td>{item.costume}</td>
									</tr>
								)
							})
                        }
                    </tbody>
                </table>
            </> : null}
        </form>
    );

}