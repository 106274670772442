import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import CheckoutForm from './CheckoutForm';
import { FormProvider, useForm } from 'react-hook-form';

import {validate} from './validate.js';
import { generateUUID } from '../utils';

import axios from 'axios';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

let stripePromise;
(async () => {
	const {publishableKey} = await axios
		.get(`${process.env.REACT_APP_STRIPE_SERVER}config`)
		.then(r => r.data);
	stripePromise = loadStripe(publishableKey);
})();
const Checkout = () => {
	const [stripeClientSecret, setStripeClientSecret] = React.useState('');
	const navigate = useNavigate();
	React.useEffect(() => {
		let orderNumber = sessionStorage.getItem('orderNumber');
		if(!orderNumber) {
			const performanceOrders = JSON.parse(sessionStorage.getItem('performanceOrders'));
			if(performanceOrders.length > 0) {
				orderNumber = `${performanceOrders[0].performance.studio}#${generateUUID()}`;
			} else {
				const studio = sessionStorage.getItem('studio');
				navigate(`/${studio}`);
			}
		}

		// Create PaymentIntent as soon as the page loads
		axios.post(`${process.env.REACT_APP_STRIPE_SERVER}create-payment-intent`, {
			amount: sessionStorage.getItem('totalPrice'),
			currency: 'usd',
			metadata: {
				order_id: orderNumber,
			}
		}).then(res => setStripeClientSecret(res.data.clientSecret))
		.catch(err => console.log(err));
	}, []);

	const appearance = {
		theme: 'stripe',
	};
	const options = {
		clientSecret: stripeClientSecret,
		appearance,
	};
	// console.log(options)
	const formOptions = validate();
	const methods = useForm(formOptions);

	return (
		<div>
			<h1 className="font-bold text-slate-800 text-3xl md:text-4xl pt-14 pb-8 px-0 md:px-4 md:px-8">Complete your order</h1>
			<main className="order-form px-0 md:px-8 pb-8">
				{stripeClientSecret && (
					<FormProvider {...methods}>
						<Elements stripe={stripePromise} options={options}>
							<CheckoutForm />
						</Elements>
					</FormProvider>
				)}
			</main>
		</div>
	);
};

export default Checkout;
