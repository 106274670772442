import * as React from 'react';

import {useNavigate} from 'react-router-dom';
import {submitOrder, saveDancers, savePerformanceOrders, saveKdsPerformanceOrders} from './api.js';
import {generateOrderCode} from '../utils';

import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../graphql/queries';
import { TailSpin } from  'react-loader-spinner';
import { generateUUID } from '../utils';

const ThankYou = () => {
	const [orderSuccess, setOrderSuccess] = React.useState(false);
	const data = JSON.parse(sessionStorage.getItem('orderData'));
	const orderItems = JSON.parse(sessionStorage.getItem('performanceOrders'));
	const dancers = JSON.parse(sessionStorage.getItem('dancers'));
	const totalPrice = sessionStorage.getItem('totalPrice');

	const navigate = useNavigate();
	const studio = sessionStorage.getItem('studio');
	const orderCode = orderItems && `${orderItems[0].performance.studio}#${generateOrderCode()}`;

	React.useEffect(() => {
		if(!data || !orderItems || !totalPrice) {
			navigate(`/${studio}`);
			return;
		}
		let orderNumber = sessionStorage.getItem('orderNumber');
		if(!orderNumber) {
			orderNumber = `${orderItems[0].performance.studio}#${generateUUID()}`;
		}
		(async () => {
			const params = new URLSearchParams(window.location.search);
			const succeeded = params.get('redirect_status');
			if(succeeded === 'succeeded') {
				let orderRecord = await submitOrder(data, orderCode, totalPrice, orderNumber);
				console.log(orderRecord);

				let orderId = orderRecord.orderId;
				if(studio !== 'KDS') {
					let dancerRecords = await saveDancers(dancers, orderItems[0].performance.studio, orderId);
					console.log(dancerRecords);
					let performanceOrderRecords = await savePerformanceOrders(orderItems, dancerRecords, orderId);
					console.log(performanceOrderRecords);
				}
				else if(studio === 'KDS') {
					const validOrders = orderItems.filter(order => order.individual === true || order.group === true || order.bestie === true);
					console.log(validOrders);
					let performanceOrderRecords = await saveKdsPerformanceOrders(validOrders, orderId);
					console.log(performanceOrderRecords);
				} 

				const orderComplete = await API.graphql(graphqlOperation(queries.getOrder, {orderId: orderRecord.orderId}));
				console.log(orderComplete);

				if(orderComplete) {
					setOrderSuccess(true);

					sessionStorage.removeItem('orderNumber');
					sessionStorage.removeItem('orderData');
					sessionStorage.removeItem('performanceOrders');
					sessionStorage.removeItem('totalPrice');
				}
			};
		})();
	}, []);
	return (
		<div className="m-auto">
			{orderSuccess ? (
				<main className="p-8">
					<div className="w-full md:w-1/2 m-auto">
						<h1 className="text-4xl font-bold mb-2">Your order has been confirmed.</h1>
						<p className="mb-6">
							<span className="text-xl">Confirmation Number: </span>
							<span className="text-2xl font-bold">{orderCode}</span>
						</p>
					</div>
					<div className="w-1/2 m-auto">
						<p className="text-lg mb-4">
							{dancers.map((dancer, i) => {
								return (
									<span key={i} className="inline-block mr-2 font-bold">
										{dancer}{i < dancers.length + 1 ? `,` : null}
									</span>
								)
							})}
							you are all set for Dance Photos!
						</p>	
						<p className="text-lg mb-4">Please follow information from your dance studio regarding photo times, costumes, etc.
						</p>
						<p className="text-lg mb-4">When your photos are ready to download, you'll receive an email from <a className="underline" href="mailto:lora@tapschoolphotos.com?subject=Question from Dance Order Confirmation Site">Lora@tapschoolphotos.com</a>. Your confirmation number will be your passcode to view your photos - we'll include it again in the email with your download link.</p>
						<p className="text-lg">We appreciate your business and look forward to seeing your dancer soon!</p>
					</div>
				</main>
			) : (
				<main className="flex justify-center p-8">
					<div className="w-full md:w-1/2 text-center m-auto">
						<div className="w-full flex justify-center mb-8">
							<TailSpin color="#CBD5E1" height={100} width={100} />
						</div>
						<h1 className="text-4xl font-bold mb-2">Completing your order...</h1>
						<p className="text-xl mb-4 px-4 py-2 rounded">
							Please do not close or refresh the page.
						</p>
					</div>
				</main>
			)}
		</div>
	);
};

export default ThankYou;
