import * as React from 'react';
import styles from './styles.module.scss';

import {newPerformance} from '../api';

import {Form, Button, TextInput, DaysAndTime} from '../../components';
import { FormProvider, useForm } from 'react-hook-form';

const CreatePerformance = () => {
	
	const formatPerformanceDays = (data, keys) => {
		const performanceDays = [];
		for (const [key, value] of Object.entries(data)) {
			if (keys.includes(key) && value) {
				performanceDays.push(key);
			}
		}
		return performanceDays.sort((a, b) => keys.indexOf(a) - keys.indexOf(b))
			.map((day) => day.charAt(0).toUpperCase() + day.slice(1))
			.join(' ');
	}
	const onSubmit = data => {
		// console.log(data);
		const dayKeys = ['mon', 'tue', 'wed', 'thu', 'fri'];
		const performanceDays = formatPerformanceDays(data, dayKeys);
		// console.log(performanceDays);
		for (const key of Object.keys(data)) {
			if (dayKeys.includes(key)) {
				delete data[key];
			}
		}
		let performance = {days: performanceDays, ...data};
		newPerformance(performance);
		// console.log(new Date(data.date).getTime());
	}
	const defaultValues = {
		mon: '',
		tue: '',
		wed: '',
		thu: '',
		fri: '',
		time: '',
		location: '',
		costume: '',
		image: '',
	};
	const methods = useForm({ defaultValues });
	
	return (
		<FormProvider {...methods}>
			<h1 className="text-3xl font-bold mb-8">Create Performance</h1>
			<Form id="performance" className={styles['admin-form']} onSubmit={onSubmit}>
				<TextInput id="title" name="title" placeholder="Title" label="Performance Title" />
				<DaysAndTime id="time" daylabel="Select Scheduled Days" timelabel="Select Scheduled Time" />
				{/* <DateInput id="date" name="date" label="Performance Date and Time" /> */}
				<TextInput id="location" name="location" placeholder="Location" label="Location" />
				<TextInput id="instructor" name="instructor" placeholder="Instructor" label="Instructor" />
				<TextInput id="costume" name="costume" type="text" placeholder="Costume" label="Costume" />
				
				<Button type="submit" buttonStyle="primary">
					Submit
				</Button>
			</Form>
		</FormProvider>
	);
};

export default CreatePerformance;
