const Button = ({type, buttonStyle, children, ...rest}) => {
	switch(buttonStyle) {
		case 'primary':
			return (
				<button 
					type={type} 
					className="rounded-lg bg-teal-700 text-slate-100 font-bold px-4 py-2 border-4 border-teal-700 duration-200 transition hover:bg-white hover:text-teal-700 shadow-lg h-12 w-max whitespace-nowrap"
					{...rest}>
					{children}
				</button>
			);
		case 'secondary':
			return (
				<button 
					type={type} 
					className="rounded-lg bg-white text-teal-700 font-bold px-4 py-2 border-4 hover:border-white border-teal-700 duration-200 transition hover:bg-teal-700 hover:text-slate-100 shadow-lg h-12 w-max whitespace-nowrap"
					{...rest}>
					{children}
				</button>
			);
		case 'disabled':
			return (
				<button
					type={type}
					className="rounded-lg font-bold px-4 py-2 border-4 border-slate-400 duration-200 transition bg-white text-slate-400 shadow-lg h-12 w-max whitespace-nowrap"
					disabled
					{...rest}>
					{children}
				</button>
			);
		default:
			return (
				<button
					type={type}
					className="rounded-lg bg-teal-700 text-slate-100 font-bold px-6 py-2.5 border-4 border-teal-700 duration-200 transition hover:bg-white hover:text-teal-700 shadow-lg w-max whitespace-nowrap"
					{...rest}>
					{children}
				</button>
			);
	}
};

export default Button;
