export const formatDay = (day) => {
	const days = {
		"Monday": "Mon",
		"Tuesday": "Tue",
		"Wednesday": "Wed",
		"Thursday": "Thu",
		"Friday": "Fri",
		"Saturday": "Sat",
		"Sunday": "Sun"
	};
	return days[day];
};