import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export const validate = () => {
	const validationSchema = Yup.object().shape({
		parentName: Yup.string()
            .required('Name is required'),
		email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
		phone: Yup.string()
			.required('Phone is required'),
		address: Yup.string()
			.required('Address is required'),
		city: Yup.string()
			.required('City is required'),
		state: Yup.string(),
		zip: Yup.string()
			.required('Zip is required'),
	});
	const formOptions = { 
		mode: 'onChange',
		reValidateMode: 'onChange', 
		resolver: yupResolver(validationSchema) 
	};
	return formOptions;
}