import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const AddDancer = ({dancers, setDancers}) => {
	const [dancer, setDancer] = React.useState('');
	let savedDancers = JSON.parse(sessionStorage.getItem('dancers'));

	function saveDancer(e) {
		e.preventDefault();
		
		
		if(!savedDancers || !savedDancers.includes(dancer)) {
			setDancers([...dancers, dancer]);
			if(!savedDancers) {
				savedDancers = [];
			}
			savedDancers.push(dancer);
			sessionStorage.setItem('dancers', JSON.stringify(savedDancers));
		}
		setDancer('');
	}
	return (
		<form onSubmit={(e) => saveDancer(e)} className="flex items-end w-full mb-8">
			<div className="w-full relative">
				<label htmlFor="dancer" className="block text-lg font-bold mb-2">
					{!savedDancers || savedDancers.length <= 0 
						? `Enter dancer's full name` 
						: `Add another dancer`
					}
				</label>
				<div className="relative">
					<input 
						type="text" 
						name="dancer" 
						placeholder="Full name"
						value={dancer || ''}
						autoComplete="off"
						className="m-0 px-6 py-3 rounded-lg block shadow-lg text-slate-800"
						onChange={(e) => setDancer(e.target.value)} />
					<button type="submit" className="absolute right-1 bottom-1">
						<FontAwesomeIcon icon={faPlusCircle} className="m-2 text-lg text-teal-800" />
					</button>
				</div>
			</div>
			
			{/* <Button type="submit" buttonStyle="primary">Save</Button> */}
		</form>
	);
};

export default AddDancer;
