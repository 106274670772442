import * as React from 'react';
import algoliasearch from 'algoliasearch/lite';
import styles from './styles.module.css';
import {generateUUID, formatDay} from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const searchClient = algoliasearch(
	'ZZ5IP7BLFQ',
	'89a8bd3889fef294efd4aaaf05830dbb'
);
const searchIndex = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_INDEX);

const Search = ({performanceOrders, setNewOrders}) => {
	const [searchTerm, setSearchTerm] = React.useState('');
	const [results, setResults] = React.useState([]);
	const studio = sessionStorage.getItem('studio');
	console.log(studio)
	React.useEffect(() => {
		if(searchTerm.length > 1) {
			search(searchTerm);
		}
	}, [searchTerm]);
	
	const search = async (term) => {
		const studioFilter = `studio: ${studio}`;
		const {hits} = await searchIndex.search(term, {
			filters: studioFilter,
		});
		setResults(hits);
	};

	const selectClass = (select) => {
		const model = {
			performanceOrderId: `PerformanceOrder#${generateUUID()}`,
			performance: select,
			dancer: '',
			individual: false,
			bestie: false,
			group: false,
		};

		let classes = [...performanceOrders];
		if(!classes.some((item) => item.performanceOrderId === model.performanceOrderId)) {
			classes.push(model);
		}
		sessionStorage.setItem('performanceOrders', JSON.stringify(classes));
		setNewOrders(classes);
		setResults([]);
	};

	return (
		<div className="col-span-3 relative mb-10">
			<form onSubmit={(e) => e.preventDefault()} className="flex items-end w-full">
			{/* <div className="flex items-end w-full"> */}
				<div className="flex flex-col justify-end w-full">
					<label htmlFor="search" className="block text-lg font-bold mb-1 ml-1">Search by class, day, time, instructor, song, or costume keywords</label>
					<div className="relative">
						<input 
							className="m-0 px-6 py-3 border-2 rounded-lg block"
							type="text" 
							name="search" 
							placeholder="Enter a class title, company, day, time, instructor, song, costume..."
							autoComplete="off" 
							defaultValue="" 
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
						<button type="submit" className="absolute right-3 bottom-3">
							<FontAwesomeIcon icon={faSearch} className="text-teal-800 text-lg" />
						</button>
					</div>
					
				</div>
				{/* <Button type="submit" buttonStyle="primary">Search</Button> */}
			</form>
			{results.length > 0 ? (
				<div className={`${styles.results} w-full absolute z-10 bg-white shadow-xl`}>
					<ul className="p-4">
						{results.map(result => {
							const day = formatDay(result.days);
							return (
								<li key={result.performanceId} className="p-1 border-b-2 block cursor-pointer justify-between items-center rounded hover:bg-slate-200" onClick={() => selectClass(result)}>
									<div className="inline-flex flex-col w-[40%] md:w-[30%] mb-auto">
										<p className={`text-sm md:text-base font-bold ${styles.title}`}>{result.title}</p>
										<p className={`text-sm md:text-base ${styles.day}`}>{day}</p>
										<p className={`text-sm md:text-base ${styles.time}`}>{result.time}</p>
									</div>
									<div className="hidden md:inline-flex flex-col w-[30%]">
										<p className={`text-sm md:text-base ${styles.instructor}`}>{result.instructor}</p>
										<p className={`text-sm md:text-base ${styles.song}`}><em>"{result.song}"</em></p>
									</div>
									<div className="inline-flex flex-col w-[60%] md:w-[40%]">
										<p className={`text-sm md:text-base md:hidden ${styles.instructor}`}>{result.instructor}</p>
										<p className={`text-sm md:text-base md:hidden ${styles.song}`}><em>"{result.song}"</em></p>
										<p className={`text-sm md:text-base ${styles.costume}`}>{result.costume}</p>
									</div>
								</li>
							)
						})}
					</ul>
				</div>
			) : null}
		</div>
	);
};

export default Search;
