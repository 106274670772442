import * as React from 'react';
import { useFormContext } from "react-hook-form";

const Form = ({ children, onSubmit, ...rest }) => {
	const methods = useFormContext();
	const {handleSubmit} = methods;
	// console.log(methods);
	return (
		<form onSubmit={handleSubmit(onSubmit)} {...rest}>
			{React.Children.map(children, child => {
				// console.log(child);
				return child.props.name
				? React.createElement(child.type, {
					...{
						...child.props,
						register: methods.register,
						control: methods.control,
						key: child.props.name
					}
				})
				: child;
			})}
		</form>
	);
};

export default Form;
