import * as React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import CreatePerformance from './CreatePerformance';
import UploadPerformances from './UploadPerformances';
import Reports from './Reports';

const Admin = () => {
	const [activeView, setActiveView] = React.useState('reports');
	return (
		<Authenticator>
			{({ signOut, user }) => (
				<div className="py-8">
					<div className="flex justify-start my-6">
						<button
							className="mr-4 rounded-lg bg-teal-700 text-slate-100 font-bold px-4 py-2 border-4 border-teal-700 duration-200 transition hover:bg-white hover:text-teal-700 shadow-lg h-12 w-max whitespace-nowrap"
							type="button" onClick={() => setActiveView('reports')}>
							Reports
						</button>
						<button
							className="mr-4 rounded-lg bg-teal-700 text-slate-100 font-bold px-4 py-2 border-4 border-teal-700 duration-200 transition hover:bg-white hover:text-teal-700 shadow-lg h-12 w-max whitespace-nowrap"
							type="button" onClick={() => setActiveView('create')}>
							Create Performance
						</button>
						<button
							className="mr-4 rounded-lg bg-teal-700 text-slate-100 font-bold px-4 py-2 border-4 border-teal-700 duration-200 transition hover:bg-white hover:text-teal-700 shadow-lg h-12 w-max whitespace-nowrap"
							type="button" onClick={() => setActiveView('upload')}>
							Upload Performances
						</button>
					</div>
					{activeView === 'create' && (
						<CreatePerformance />
					)}
					{activeView === 'upload' && (
						<UploadPerformances />
					)}
					{activeView === 'reports' && (
						<Reports />
					)}
				</div>
			)}
		</Authenticator>
	);
};

export default Admin;
